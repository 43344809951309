@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Tint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');



body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#turn {
    display: none;
    z-index: 100;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.4);
    justify-content: center;
    align-items: center;

    .text {
        width: 40vw;
        height: 20vh;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-white);
        padding: 16px;
        text-align: center;
    }
}

@media (orientation: portrait) {
    #turn {
        display: flex;
    }
}



.react-datepicker {
    display: flex !important;
}

.react-datepicker__time-container {
    border-left: none !important;
}

.react-datepicker__month-container {
    border-right: 1px solid #aeaeae;
}